import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { stakeContract } from "../../utils/contractHelpers";

export const Rewards = () => {
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = environment.Staking;
  const contract = stakeContract(tokenAddress, web3);
  const CRewards = useCallback(async (account, tokenId) => {
    const approved = await contract.methods
      .checkReward(account, tokenId)
      .call();
    return approved;
  }, [account, contract]);

  return { CRewards: CRewards };
};

export default Rewards;