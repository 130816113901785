import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getMintContract } from "../../utils/contractHelpers";

export const BalanceOF = () => {
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = environment.Mint;
  const contract = getMintContract(tokenAddress, web3);
  const Balances = useCallback(
    async (account, tokenId) => {
      if (web3) {
        try {
          const approved = await contract.methods
            .balanceOfBatch(account, tokenId)
            .call();
          console.log("approved======>", approved);
          return approved;
        } catch (e) {
          console.log("======>", e);
        }
      }
    },
    [web3]
  );

  return { Balances: Balances };
};

export default BalanceOF;
