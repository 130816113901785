import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { stakeContract } from "../../utils/contractHelpers";

export const UnStakeForAll = () => {
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const stakingAddress = environment.Staking;
  const contract = stakeContract(stakingAddress, web3);
  const UnStakeTokens = useCallback(
    async (tokenIds) => {
      try {
        console.log("ooooooo", tokenIds)
        const gas = await contract.methods
          .unstakeMany(tokenIds)
          .estimateGas({ from: account });

        const staked = await contract.methods
          .unstakeMany(tokenIds)
          .send({ from: account, gas: gas, gasPrice: "81500000000" })
          .on("transactionHash", (tx) => {
            return tx.transactionHash;
          })

        // tokenIds.map(token => {
        //   axios.post("https://mints-records.herokuapp.com/record", { accountAddress: account, tokenId: token });
        // })
        return staked;
      } catch (error) {
        throw error;
      }

    },
    [account, contract]
  );

  return { UnStakeTokens: UnStakeTokens };
};

export default UnStakeForAll;
