import React, { useState, useEffect } from "react";
import logo from "../../Assets/img/logo-light-widget.png";

// import { Button } from 'react-bootstrap';
import { Link } from "react-router-dom";

import "./navbar.css";
// import { useWeb3React } from '@web3-react/core'
import useAuth from "../../hooks/useAuth";

import { Web3ReactProvider, useWeb3React } from "@web3-react/core";
import { InjectedConnector } from "@web3-react/injected-connector";

// import Web3 from "web3";

// import { Link } from 'react-router-dom'

const Navbar = () => {
  const { account } = useWeb3React();
  // const { userSign } = Signature(account);
  const { login, logout } = useAuth();
  // setAccountt(account);
  const connectMetaMask = async () => {
    try {
      if (account) {
        logout();
        localStorage.setItem("flag", false);
      } else {
        login("injected");
        // activate(injected);
        localStorage.setItem("connectorId", "injected");
        localStorage.setItem("flag", true);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    // connectMetaMask();
  }, []);
  return (
    <div className="container-fluid bg-dark bgBlack">
      <div className="row">
        <div className="col">
          <nav
            className="navbar navbar-expand-lg navbar-dark bg-dark bgBlack"
            style={{ zIndex: "5" }}
          >
            <div className="container bg-dark bgBlack gkdjfkd">
              <Link className="navbar-brand" to="/">
                <img src={logo} className="img-fluid w-100" />
              </Link>
              <button
                className="navbar-toggler "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse ms-auto" id="navbarNav">
                <ul className="navbar-nav ps-lg-5">
                  <li className="nav-item px-3">
                    <Link to="/" className="nav-link lead ">
                      HOME
                    </Link>
                  </li>
                  <li className="nav-item px-3 ">
                    <Link to="/staking" className="nav-link  lead ">
                      STAKING
                    </Link>
                  </li>
                </ul>
                {account ? (
                  <button
                    className="ms-auto mt-lg-0 mt-3 button14"
                    onClick={connectMetaMask}
                  >
                    DISCONNECT WALLET
                  </button>
                ) : (
                  <button
                    className="ms-auto my-lg-0 ms-5 my-3 button14"
                    onClick={connectMetaMask}
                  >
                    CONNECT WALLET
                  </button>
                )}
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Navbar;


// intro paragraph

// Customers Can Get Assistance, one liner

// APY Rewards Daily 16.18%
// discard to telegram 
// caps spell

// Interest Rewards Daily 16.18%

// 16.18 / 365 = 0.04432876712 daily %

// x %age in 365 days.

// checkbox to tick mark


// Staked PBLCNFTs
// Un-Staked PBLCNFTs

// value of NFTs insteag of token ids. e.g 

// $1000000 PBLCNFT

//  on stake / unstake pagee

// validations are completed.
// YOUR STATE EARNED REWARD ON.
// loader on loading staking page.
// Grpah immigration.
// dapp code to github.

// calculated reward. according to ammount.

// user select nft amount.


//  1 - 10
//  1 - 20






// npm install - g @graphprotocol/graph-cli
// yarn global add @graphprotocol/graph-cli
// graph init --studio nwo - pblcnft - stake
// graph auth --studio 4042e6a2c13925e2277d7dc1cf081903
// cd nwo - pblcnft - stake
// graph codegen && graph build
// graph deploy --studio nwo-pblcnft-stake

// graph deploy --studio zoranftjj

// DEVELOPMENT QUERY URL
// https://api.studio.thegraph.com/query/36920/nwo-pblcnft-stake/v0.0.1
// https://api.studio.thegraph.com/query/36920/nwo-pblcnft-stake/0.0.5

// DEPLOYMENT ID
// QmVsXoASybPKwsg2qwyKhek6YJUQhr1uGw5cJMQnX731Mg
// https://gateway.thegraph.com/api/[QmVsXoASybPKwsg2qwyKhek6YJUQhr1uGw5cJMQnX731Mg]/subgraphs/id/2zifLagmRJiDA1yAy37b28ZoekdKjs2LkD24ybkSMgRm