import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Contact from "./components/Contact/contact";
import Footer from "./components/Footer/footer";
import Navbar from "./components/Navbar/navbar";
import Staking from "./components/Staking/staking";
import Card from "./components/Card/cards";
import useEagerConnect from "./hooks/useEagerConnect";
import "./App.css";

function App() {
  useEagerConnect();
  return (
    <>
      <Router>
        <div id="lase">
          <Navbar />
          <Switch>
            <Route exact path="/"><Card/><Contact/></Route>
            <Route exact path="/Staking" ><Staking/><Contact display={'d-none'}/></Route>
          </Switch>          <Footer />
        </div>
      </Router>
    </>
  );
}

export default App;
