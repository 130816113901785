import React, { useState, useEffect } from "react";
import Modull from "../Modals/modal";
import Modull2 from "../Modals/modal2";
import axios from "axios";
import "./staking.css";
import im1 from "./nfts/234.jpg";
import im2 from "./nfts/index123.jpg";
import im3 from "./nfts/index3245.jpg";
import im4 from "./nfts/index354678.jpg";
import { useWeb3React } from "@web3-react/core";
import BalanceOF from "../../hooks/dataFetchers/balanceBatch";
import StakeForAll from "../../hooks/dataSenders/getStakeAll";
import UnStakeForAll from "../../hooks/dataSenders/getUnStakeAll";
import CheckApp from "../../hooks/dataSenders/approveAll";
import SetApp from "../../hooks/dataSenders/setApproveAll";
import Loader from "../../hooks/loader";
import { toast, ToastContainer } from "react-toastify";
import Rewards from "../../hooks/dataFetchers/checkRewards";
import "react-toastify/dist/ReactToastify.css";
import fd from "../../Assets/img/not_found.png";

const Staking = () => {
  const { Balances } = BalanceOF();
  const [total, setTotal] = useState([]);
  const [balanceLength, setBalanceLength] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selected2, setSelected2] = useState([]);

  const [stak, setStak] = useState([]);

  const [unstak, setUnStak] = useState([]);
  const { account } = useWeb3React();
  const [appro, setAppro] = useState(false);
  const [rend, setRend] = useState(false);
  const [loader, setLoader] = useState(false);
  const { StakeAll } = StakeForAll();
  const [inpoVal, setInpoVal] = useState();
  const { UnStakeTokens } = UnStakeForAll();
  const { VerAppr } = CheckApp();
  const { Approve } = SetApp();
  const [inputList, setInputList] = useState([{ tokenId: "", amount: "" }]);
  const [amounts, setamounts] = useState([]);

  const handleClick = (index) => {
    let val = selected[index] === index;
    let dumm = selected;
    if (val) {
      dumm[index] = 0;
    } else {
      dumm[index] = index;
    }
    setSelected(dumm);
    setRend(!rend);
  };

  const handleClick2 = (index) => {
    let dumArr = selected2;
    let find = dumArr.find((i) => {
      return i === index;
    });

    if (find) {
      dumArr = dumArr.filter((i) => {
        return i !== index;
      });
    } else {
      dumArr.push(index);
    }

    // let val = selected2[index] === index;
    // let dumm = selected2;
    // if (val) {
    //   dumm[index] = 0;
    // } else {
    //   dumm[index] = index;
    // }
    //

    setSelected2(dumArr);
    setRend(!rend);
  };

  useEffect(async () => {
    if (account) {
      GetNfts();
    }
  }, [account]);

  const GetNfts = async () => {
    let acc = [
      account,
      account,
      account,
      account,
      account,
      account,
      account,
      account,
      account,
      account,

      account,
      account,
      account,
      account,
      account,
    ];
    // let tokas = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    // mainnet ids
    let tokas = [
      "35765170590814674817329738340066840990845959557561780372227731964376303796227",
      "35765170590814674817329738340066840990845959557561780372227731964376303796237",
      "35765170590814674817329738340066840990845959557561780372227731964376303796242",


      "35765170590814674817329738340066840990845959557561780372227731964376303796239",
      "35765170590814674817329738340066840990845959557561780372227731964376303796229",
      "35765170590814674817329738340066840990845959557561780372227731964376303796230",


      "35765170590814674817329738340066840990845959557561780372227731964376303796243",


      "35765170590814674817329738340066840990845959557561780372227731964376303796231",
      "35765170590814674817329738340066840990845959557561780372227731964376303796232",


      "35765170590814674817329738340066840990845959557561780372227731964376303796244",


      "35765170590814674817329738340066840990845959557561780372227731964376303796233",
      "35765170590814674817329738340066840990845959557561780372227731964376303796234",
      "35765170590814674817329738340066840990845959557561780372227731964376303796235",
      "35765170590814674817329738340066840990845959557561780372227731964376303796236",
      "35765170590814674817329738340066840990845959557561780372227731964376303796225",
    ];

    // testnet ids
    // let tokas = [
    //   "35765170590814674817329738340066840990845959557561780372227731964376303796225",
    //   "35765170590814674817329738340066840990845959557561780372227731964376303796228",
    //   "35765170590814674817329738340066840990845959557561780372227731964376303796227",
    // ];

    // let tokas = [
    //   "35765170590814674817329738340066840990845959557561780372227731964376303796239",
    //   "35765170590814674817329738340066840990845959557561780372227731964376303796233",
    //   "35765170590814674817329738340066840990845959557561780372227731964376303796233",
    // ];

    const res = await Balances(acc, tokas);

    const ress = res.find((e) => {
      if (e != 0) {
        return e;
      }
    });
    console.log("setTotal", ress);
    await setTotal(res);
    await setBalanceLength(ress);
    // axios
    //   .get(
    //     "https://deep-index.moralis.io/api/v2/nft/0xD1ed5CA65c2b2a150293aaaE0fDe7Fd88Bb22733/owners?chain=goerli&format=decimal",
    //     { headers: { "X-API-Key": "test" } }
    //   )
    //   .then((res) => {
    //     let filtz = res.data.result.filter((i) => {
    //       return i?.owner_of === account?.toLowerCase();
    //     });

    //     // setUnst(filtz)
    //   });
  };

  useEffect(async () => {
    if (account) {
      const res = await VerAppr(account);

      getStakedNfts();
    }
  }, [account]);

  console.log("setTotal", balanceLength);

  const getStakedNfts = () => {
    var data = JSON.stringify({
      query: `{
        stakes(where: {staker:  "${account}"}){
          staker
          id
          tokenId
          amount
        }
      }`,
      variables: {},
    });

    var config = {
      method: "post",
      // url: "https://api.thegraph.com/subgraphs/name/muhammadwaqas4/pblc-staking",
      // url: "https://api.studio.thegraph.com/query/28738/pblc-nft/0.0.5",
      // url: "https://api.studio.thegraph.com/query/36920/nwo-pblcnft-stake/v0.0.1",
      url: "https://api.studio.thegraph.com/query/36920/nwo-pblcnft-stake/0.0.6",
      // url: "https://gateway.thegraph.com/api/QmVsXoASybPKwsg2qwyKhek6YJUQhr1uGw5cJMQnX731Mg/subgraphs/id/2zifLagmRJiDA1yAy37b28ZoekdKjs2LkD24ybkSMgRm",

      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        let stak = response.data?.data?.stakes;
        console.log("response.data?.data?.stakes", response.data?.data?.stakes);

        setStak(stak);
      })
      .catch(function (error) {
        setLoader(false);
      });
  };

  useEffect(async () => {
    if (account) {
      const res = await VerAppr(account);
      await setAppro(res);
    }
    // let tokas = [
    //   "35765170590814674817329738340066840990845959557561780372227731964376303796225",
    //   "35765170590814674817329738340066840990845959557561780372227731964376303796228",
    //   "35765170590814674817329738340066840990845959557561780372227731964376303796227",
    // ];
    // handleAddClick();
  }, [account]);

  useEffect(async () => {
    if (total?.length > 0) {
      // console.log("handleAddClick");
      handleAddClick();
    }
  }, [total]);

  const handleAddClick = () => {
    let dumObj = [];
    let dumAmounts = [];
    let tokas = [
      "35765170590814674817329738340066840990845959557561780372227731964376303796225",
      "35765170590814674817329738340066840990845959557561780372227731964376303796228",
      "35765170590814674817329738340066840990845959557561780372227731964376303796227",
      "35765170590814674817329738340066840990845959557561780372227731964376303796225",
      "35765170590814674817329738340066840990845959557561780372227731964376303796228",
      "35765170590814674817329738340066840990845959557561780372227731964376303796227",
      "35765170590814674817329738340066840990845959557561780372227731964376303796225",
      "35765170590814674817329738340066840990845959557561780372227731964376303796228",
      "35765170590814674817329738340066840990845959557561780372227731964376303796227",
    ];

    for (let i of total) {
      dumObj.push({ tokenId: "", amount: "" });
      dumAmounts.push(0);
    }
    setInputList(dumObj);
    setamounts(dumAmounts);
  };
  const handleInputChange = (e, item, index) => {
    // let tokas = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    // testnet ids
    // if (parseInt(e.target.value) >= 0) {
    let tokas = [
      "35765170590814674817329738340066840990845959557561780372227731964376303796227",
      "35765170590814674817329738340066840990845959557561780372227731964376303796237",
      "35765170590814674817329738340066840990845959557561780372227731964376303796242",


      "35765170590814674817329738340066840990845959557561780372227731964376303796239",
      "35765170590814674817329738340066840990845959557561780372227731964376303796229",
      "35765170590814674817329738340066840990845959557561780372227731964376303796230",


      "35765170590814674817329738340066840990845959557561780372227731964376303796243",


      "35765170590814674817329738340066840990845959557561780372227731964376303796231",
      "35765170590814674817329738340066840990845959557561780372227731964376303796232",


      "35765170590814674817329738340066840990845959557561780372227731964376303796244",


      "35765170590814674817329738340066840990845959557561780372227731964376303796233",
      "35765170590814674817329738340066840990845959557561780372227731964376303796234",
      "35765170590814674817329738340066840990845959557561780372227731964376303796235",
      "35765170590814674817329738340066840990845959557561780372227731964376303796236",
      "35765170590814674817329738340066840990845959557561780372227731964376303796225",
    ];

    // setInpoVal(parseInt(e.target.value));

    const { value } = e.target;
    // if (parseInt(value) < 0) {
    if (parseInt(e.target.value) < 0) {
      console.log("ccccccc");
      let dumArr = amounts;
      dumArr[index] = 0;
      setamounts(dumArr);
      setRend(!rend);

      const list = [...inputList];
      list[index]["tokenId"] = tokas[index];
      list[index]["amount"] = 0;
      setInputList(list);
      setRend(!rend);
    } else if (parseInt(e.target.value) <= parseInt(item)) {
      // const list = [...inputList];
      // list[index]["tokenId"] = tokas[index];
      // list[index]["amount"] = 0;
      // setInputList(list);

      let dumArr = amounts;
      dumArr[index] = e.target.value;
      setamounts(dumArr);
      setRend(!rend);

      const list = [...inputList];
      list[index]["tokenId"] = tokas[index];
      list[index]["amount"] = value;
      setInputList(list);
      setRend(!rend);
    }
    // else if (parseInt(value) <= parseInt(item)) {

    //   const list = [...inputList];
    //   list[index]["tokenId"] = tokas[index];
    //   list[index]["amount"] = value;
    //   setInputList(list);
    //   setRend(!rend);

    // }
    else {
      toast?.error(`input exceeds than ${item}`);
      // setInpoVal(item)
      console.log("ccccccc");
      let dumArr = amounts;
      dumArr[index] = item;
      setamounts(dumArr);
      setRend(!rend);

      const list = [...inputList];
      list[index]["tokenId"] = tokas[index];
      list[index]["amount"] = item;
      setInputList(list);
      setRend(!rend);
    }
    // } else {
    //   // setInpoVal(0);
    // }
  };

  const handleInputChange1 = (e, item, index) => {
    console.log("laksddsaasd", e.target.value);
    if (
      parseInt(e.target.value) <= parseInt(item) &&
      parseInt(e.target.value) > 0
    ) {
      let dumArr = amounts;
      dumArr[index] = e.target.value;
      setamounts(dumArr);
      setRend(!rend);
    } else {
      console.log("ccccccc");
      let dumArr = amounts;
      dumArr[index] = 0;
      setamounts(dumArr);
      setRend(!rend);
    }
  };

  // console.log("index----->input ", inputList);

  // console.log("total======>", total, amounts);

  const handlInput = (e, item, index) => {
    const value = e.target.value;
    if (value < 0) {
      setInpoVal(0);
    } else if (parseInt(value) <= parseInt(item)) {
      setInpoVal(value);
    } else {
      toast?.error(`input exceeds than ${item}`);
      setInpoVal(item);
    }
  };
  // approveNfts
  const approveNfts = async () => {
    try {
      setLoader(true);
      const res = await Approve(account);

      if (res) {
        await setAppro(res);
        await setLoader(false);
      }
    } catch (e) {
      console.log("StakeAllNFTs ", e);
      await setLoader(false);
    }
  };

  const StakeAllNFTs = async () => {
    try {
      setLoader(true);
      console.log("StakeAllNFTs ", inputList[0]);
      console.log("StakeAllNFTs ", inputList.length);
      let tokenIdArr = [];
      let tokenAmountArr = [];

      for (let i = 0; i < inputList.length; i++) {
        if (inputList[i].amount > 0 && inputList[i].amount != "") {
          tokenIdArr.push(inputList[i].tokenId);
          tokenAmountArr.push(inputList[i].amount);
        }
      }
      console.log("tokenIdArr ", tokenIdArr);
      console.log("tokenAmountArr ", tokenAmountArr);
      if (tokenIdArr.length > 0 && tokenAmountArr.length > 0) {
        if (appro) {
          const res = await StakeAll(tokenIdArr, tokenAmountArr);
          await GetNfts();
          await setLoader(false);
        } else {
          try {
            const res = await Approve(account);
            if (res) {
              const reso = await await StakeAll(tokenIdArr, tokenAmountArr);
              await alert(
                "Your changes may take few seconds to appear, else Refresh after few seconds to view changes"
              );
              await GetNfts();
              await setLoader(false);
            }
          } catch (e) {
            toast?.error(e?.message);
            setLoader(false);
          }
        }
      } else {
        toast.error("Please select at least one nft");
        setLoader(false);
      }
    } catch (e) {
      console.log("StakeAllNFTs ", e);
      await setLoader(false);
    }
  };

  const StakeAlls = async () => {
    if (selected.length > 0) {
      setLoader(true);
      let dumArr = selected;

      // let tokas = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

      // mainnet ids
      let tokas = [

        "35765170590814674817329738340066840990845959557561780372227731964376303796227",
        "35765170590814674817329738340066840990845959557561780372227731964376303796237",
        "35765170590814674817329738340066840990845959557561780372227731964376303796242",


        "35765170590814674817329738340066840990845959557561780372227731964376303796239",
        "35765170590814674817329738340066840990845959557561780372227731964376303796229",
        "35765170590814674817329738340066840990845959557561780372227731964376303796230",


        "35765170590814674817329738340066840990845959557561780372227731964376303796243",


        "35765170590814674817329738340066840990845959557561780372227731964376303796231",
        "35765170590814674817329738340066840990845959557561780372227731964376303796232",


        "35765170590814674817329738340066840990845959557561780372227731964376303796244",


        "35765170590814674817329738340066840990845959557561780372227731964376303796233",
        "35765170590814674817329738340066840990845959557561780372227731964376303796234",
        "35765170590814674817329738340066840990845959557561780372227731964376303796235",
        "35765170590814674817329738340066840990845959557561780372227731964376303796236",
        "35765170590814674817329738340066840990845959557561780372227731964376303796225",

      ];

      // testnet ids
      // let tokas = [
      //   "35765170590814674817329738340066840990845959557561780372227731964376303796225",
      //   "35765170590814674817329738340066840990845959557561780372227731964376303796228",
      //   "35765170590814674817329738340066840990845959557561780372227731964376303796227",
      // ];
      // let tokas = [
      //   "35765170590814674817329738340066840990845959557561780372227731964376303796239",
      //   "35765170590814674817329738340066840990845959557561780372227731964376303796233",
      //   "35765170590814674817329738340066840990845959557561780372227731964376303796233",
      // ];

      let dumArr2 = [];
      let dumArr3 = [];
      for (let i of dumArr) {
        if (i) {
          let vall = tokas[i - 1];
          dumArr2.push(vall);
          dumArr3.push(i);
        }
      }

      let amounts = [];
      for (let i of dumArr3) {
        amounts.push(total[i - 1]);
      }
      if (appro) {
        try {
          const res = await StakeAll(dumArr2, amounts);

          await GetNfts();
          await setLoader(false);
        } catch (e) {
          toast?.error(e?.message);
          setLoader(false);
        }
      } else {
        try {
          const res = await Approve(account);
          if (res) {
            const reso = await StakeAll(dumArr2, amounts);
            await alert(
              "Your changes may take few seconds to appear, else Refresh after few seconds to view changes"
            );
            await GetNfts();
            await setLoader(false);
          }
        } catch (e) {
          toast?.error(e?.message);
          setLoader(false);
        }
      }
    } else {
      toast.error("Please select at least one nft");
      setLoader(false);
    }
  };

  const UnStakeAlls = async () => {
    if (selected2?.length > 0) {
      try {
        setLoader(true);
        let dumArr = selected2;

        const res = await UnStakeTokens(dumArr);
        await alert(
          "Your changes may take few seconds to appear, else Refresh after few seconds to view changes"
        );
        await GetNfts();
        await setLoader(false);
      } catch (e) {
        toast?.error(e?.message);
        setLoader(false);
      }
    } else {
      toast.error("Please select at least one nft");
      await setLoader(false);
    }
  };

  return (
    <>
      <ToastContainer />
      {loader && <Loader />}
      <div className="container staking-main mt-5">
        <div className="row mt-5 mb-5 justify-content-start justify-content-start">
          <h2 className="mt-5 mb-3 text-white text-md-start text-center ">
            Staked PBLCNFTs
          </h2>
          {stak?.length > 0 ? (
            <>
              {stak?.map((item, index) => {

                console.log("----->index", item, index)
                return (
                  <div key={index}
                    className="col-lg-4 col-sm-6 col-12 mt-5 text-center">
                    <div className="card p-2 mx-auto mx-lg-0 w-lg-75 w-100">
                      <div className="pretty p-default p-round p-smooth p-plain">
                        <div className="pretty p-default p-round p-smooth p-plain">
                          <h6 className="text-left text-light">
                            <span className="">{item?.amount}</span>{" "}
                          </h6>
                        </div>
                        <input
                          checked={selected2.find((r) => r === item.tokenId)}
                          // checked={
                          //   item?.tokenId ===
                          //   selected2[index]
                          // }
                          type="checkbox"
                          name="checkbox-checked"
                          onClick={() => handleClick2(item?.tokenId)}
                        />
                      </div>
                      {/* <img
                        className="card-img-top"
                        src={im1}
                        alt="Card image cap"
                      /> */}
                      {/* <div className="card-body"> */}
                      {/* <p className="card-text text-white font-weight-bold">
                       PBLCNFT #{item?.tokenId}
                       </p> */}
                      {/* {item?.tokenId === "1" && */}
                      {/* // {item?.tokenId === "35765170590814674817329738340066840990845959557561780372227731964376303796225" && */}
                      {item?.tokenId ===
                        "35765170590814674817329738340066840990845959557561780372227731964376303796239" && (
                          <>
                            <img
                              className="card-img-top"
                              src={im1}
                              alt="Card image cap"
                            />
                            <div className="card-body">
                              <p className="card-text text-white font-weight-bold">
                                PBLCNFT ${1}
                              </p>
                            </div>
                          </>
                        )}

                      {item?.tokenId ===
                        "35765170590814674817329738340066840990845959557561780372227731964376303796227" && (
                          <>
                            <img
                              className="card-img-top"
                              src={im1}
                              alt="Card image cap"
                            />
                            <div className="card-body">
                              <p className="card-text text-white font-weight-bold">
                                PBLCNFT ${1}
                              </p>
                            </div>
                          </>
                        )}


                      {item?.tokenId ===
                        "35765170590814674817329738340066840990845959557561780372227731964376303796237" && (
                          <>
                            <img
                              className="card-img-top"
                              src={im1}
                              alt="Card image cap"
                            />
                            <div className="card-body">
                              <p className="card-text text-white font-weight-bold">
                                PBLCNFT ${1}
                              </p>
                            </div>
                          </>
                        )}

                      {item?.tokenId ===
                        "35765170590814674817329738340066840990845959557561780372227731964376303796242" && (
                          <>
                            <img
                              className="card-img-top"
                              src={im1}
                              alt="Card image cap"
                            />
                            <div className="card-body">
                              <p className="card-text text-white font-weight-bold">
                                PBLCNFT ${1}
                              </p>
                            </div>
                          </>
                        )}
                      {/* {item?.tokenId === "35765170590814674817329738340066840990845959557561780372227731964376303796228" && */}
                      {item?.tokenId ===
                        "35765170590814674817329738340066840990845959557561780372227731964376303796229" && (
                          <>
                            <img
                              className="card-img-top"
                              src={im1}
                              alt="Card image cap"
                            />
                            <div className="card-body">
                              <p className="card-text text-white font-weight-bold">
                                PBLCNFT ${10}
                              </p>
                            </div>
                          </>
                        )}

                      {item?.tokenId ===
                        "35765170590814674817329738340066840990845959557561780372227731964376303796230" && (
                          <>
                            <img
                              className="card-img-top"
                              src={im1}
                              alt="Card image cap"
                            />
                            <div className="card-body">
                              <p className="card-text text-white font-weight-bold">
                                PBLCNFT ${50}
                              </p>
                            </div>
                          </>

                        )}

                      {item?.tokenId ===
                        "35765170590814674817329738340066840990845959557561780372227731964376303796243" && (
                          <>
                            <img
                              className="card-img-top"
                              src={im1}
                              alt="Card image cap"
                            />
                            <div className="card-body">
                              <p className="card-text text-white font-weight-bold">
                                PBLCNFT ${50}
                              </p>
                            </div>
                          </>
                        )}

                      {/* {item?.tokenId === "2" && */}

                      {item?.tokenId ===
                        "35765170590814674817329738340066840990845959557561780372227731964376303796231" && (
                          <>
                            <img
                              className="card-img-top"
                              src={im1}
                              alt="Card image cap"
                            />
                            <div className="card-body">
                              <p className="card-text text-white font-weight-bold">
                                PBLCNFT ${100}
                              </p>
                            </div>
                          </>
                        )}

                      {/* {item?.tokenId === "35765170590814674817329738340066840990845959557561780372227731964376303796227" && */}
                      {item?.tokenId ===
                        "35765170590814674817329738340066840990845959557561780372227731964376303796232" && (
                          <>
                            <img
                              className="card-img-top"
                              src={im4}
                              alt="Card image cap"
                            />
                            <div className="card-body">
                              <p className="card-text text-white font-weight-bold">
                                PBLCNFT ${500}
                              </p>
                            </div>
                          </>
                        )}

                      {item?.tokenId ===
                        "35765170590814674817329738340066840990845959557561780372227731964376303796244" && (
                          <>
                            <img
                              className="card-img-top"
                              src={im4}
                              alt="Card image cap"
                            />
                            <div className="card-body">
                              <p className="card-text text-white font-weight-bold">
                                PBLCNFT ${500}
                              </p>
                            </div>
                          </>
                        )}

                      {item?.tokenId ===
                        "35765170590814674817329738340066840990845959557561780372227731964376303796233" && (
                          <>
                            <img
                              className="card-img-top"
                              src={im4}
                              alt="Card image cap"
                            />
                            <div className="card-body">
                              <p className="card-text text-white font-weight-bold">
                                PBLCNFT ${1000}
                              </p>
                            </div>
                          </>
                        )}

                      {item?.tokenId ===
                        "35765170590814674817329738340066840990845959557561780372227731964376303796234" && (
                          <>
                            <img
                              className="card-img-top"
                              src={im4}
                              alt="Card image cap"
                            />
                            <div className="card-body">
                              <p className="card-text text-white font-weight-bold">
                                PBLCNFT ${"10,000"}
                              </p>
                            </div>
                          </>
                        )}

                      {item?.tokenId ===
                        "35765170590814674817329738340066840990845959557561780372227731964376303796235" && (
                          <>
                            <img
                              className="card-img-top"
                              src={im2}
                              alt="Card image cap"
                            />
                            <div className="card-body">
                              <p className="card-text text-white font-weight-bold">
                                PBLCNFT ${"50,000"}
                              </p>
                            </div>
                          </>
                        )}

                      {item?.tokenId ===
                        "35765170590814674817329738340066840990845959557561780372227731964376303796236" && (
                          <>
                            <img
                              className="card-img-top"
                              src={im2}
                              alt="Card image cap"
                            />
                            <div className="card-body">
                              <p className="card-text text-white font-weight-bold">
                                PBLCNFT ${"100,000"}
                              </p>
                            </div>
                          </>
                        )}

                      {item?.tokenId ===
                        "35765170590814674817329738340066840990845959557561780372227731964376303796225" && (
                          <>
                            <img
                              className="card-img-top"
                              src={im3}
                              alt="Card image cap"
                            />
                            <div className="card-body">
                              <p className="card-text text-white font-weight-bold">
                                PBLCNFT ${"1,000,000"}
                              </p>
                            </div>
                          </>
                        )}
                      {/* </div> */}
                    </div>
                  </div>
                );
              })}
              <div className="col-12 text-md-start text-center mt-5">
                <button
                  onClick={() => UnStakeAlls()}
                  className="button12 text-center m px-4 py-2 me-lg-1 me-sm-5 mb-lg-0 mb-sm-3 mt-lg-0 mt-3 ms-lg-0 ms-sm-5 px-5 py-3"
                >
                  Unstake
                </button>
              </div>
            </>
          ) : (
            <div className="row justify-content-around align-items-center nt_found">
              <div className="col-8 offset-md-0 offset-4">
                <img src={fd} alt="" className="img-fluid w-50" />
                <div className="col-8">
                  <h4 className="ms-xl-4">No staked PBLCNFTs to show</h4>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* //Unstaked */}

        <div className="row justify-content-lg-start justify-content-center mt-md-0 mt-5">
          <h2 className="mt-5 mb-5 text-white text-md-start text-center ">
            Unstaked PBLCNFTs
          </h2>
          {balanceLength > 0 ? (
            <>
              {total?.map((item, index) => {
                return (
                  <>
                    {parseInt(item) > 0 && (
                      <div className="col-lg-4 col-sm-6 col-12 mt-5 text-center">
                        <div className="card p-2 mx-auto mx-lg-0 w-lg-75 w-100">
                          <div className="pretty p-default p-round p-smooth p-plain">
                            <input
                              placeholder="enter"
                              value={amounts[index]}
                              onChange={(e) => {
                                if (e.target.value) {
                                  handleInputChange(e, item, index);
                                }
                              }}
                              type="number"
                              className="w-25 inpo"
                              autoFocus={true}
                            />

                            <h6 className="text-right text-light">
                              <span className="">{item}</span>{" "}
                            </h6>

                            {/* <input
                           checked={index + 1 === selected[index + 1]}
                           type="checkbox"
                           name="checkbox-checked"

                           // onClick={() => handleClick(parseInt(item?.tokenId))}
                           onClick={() => handleClick(index + 1)}
                           /> */}
                          </div>
                          {/* <img
                            className="card-img-top"
                            src={im2}
                            alt="Card image cap"
                          /> */}
                          <div>
                            {index === 0 && (
                              <>
                                <img
                                  className="card-img-top"
                                  src={im1}
                                  alt="Card image cap"
                                />
                                <div className="card-body">
                                  <p className="card-text text-white font-weight-bold">
                                    PBLCNFT ${1}
                                  </p>
                                </div>
                              </>
                            )}
                            {index === 1 && (
                              <>
                                <img
                                  className="card-img-top"
                                  src={im1}
                                  alt="Card image cap"
                                />
                                <div className="card-body">
                                  <p className="card-text text-white font-weight-bold">
                                    PBLCNFT ${1}
                                  </p>
                                </div>
                              </>
                            )}
                            {index === 2 && (
                              <>
                                <img
                                  className="card-img-top"
                                  src={im1}
                                  alt="Card image cap"
                                />
                                <div className="card-body">
                                  <p className="card-text text-white font-weight-bold">
                                    PBLCNFT ${1}
                                  </p>
                                </div>
                              </>
                            )}
                            {index === 3 && (
                              <>
                                <img
                                  className="card-img-top"
                                  src={im1}
                                  alt="Card image cap"
                                />
                                <div className="card-body">
                                  <p className="card-text text-white font-weight-bold">
                                    PBLCNFT ${1}
                                  </p>
                                </div>
                              </>
                            )}
                            {index === 4 && (
                              <>
                                <img
                                  className="card-img-top"
                                  src={im1}
                                  alt="Card image cap"
                                />
                                <div className="card-body">
                                  <p className="card-text text-white font-weight-bold">
                                    PBLCNFT ${10}
                                  </p>
                                </div>
                              </>
                            )}
                            {index === 5 && (
                              <>
                                <img
                                  className="card-img-top"
                                  src={im1}
                                  alt="Card image cap"
                                />
                                <div className="card-body">
                                  <p className="card-text text-white font-weight-bold">
                                    PBLCNFT ${50}
                                  </p>
                                </div>
                              </>
                            )}
                            {index === 6 && (
                              <>
                                <img
                                  className="card-img-top"
                                  src={im1}
                                  alt="Card image cap"
                                />
                                <div className="card-body">
                                  <p className="card-text text-white font-weight-bold">
                                    PBLCNFT ${50}
                                  </p>
                                </div>
                              </>
                            )}

                            {index === 7 && (
                              <>
                                <img
                                  className="card-img-top"
                                  src={im1}
                                  alt="Card image cap"
                                />
                                <div className="card-body">
                                  <p className="card-text text-white font-weight-bold">
                                    PBLCNFT ${100}
                                  </p>
                                </div>
                              </>
                            )}
                            {index === 8 && (
                              <>
                                <img
                                  className="card-img-top"
                                  src={im4}
                                  alt="Card image cap"
                                />
                                <div className="card-body">
                                  <p className="card-text text-white font-weight-bold">
                                    PBLCNFT ${500}
                                  </p>
                                </div>
                              </>
                            )}
                            {index === 9 && (
                              <>
                                <img
                                  className="card-img-top"
                                  src={im4}
                                  alt="Card image cap"
                                />
                                <div className="card-body">
                                  <p className="card-text text-white font-weight-bold">
                                    PBLCNFT ${500}
                                  </p>
                                </div>
                              </>
                            )}
                            {index === 10 && (
                              <>
                                <img
                                  className="card-img-top"
                                  src={im4}
                                  alt="Card image cap"
                                />
                                <div className="card-body">
                                  <p className="card-text text-white font-weight-bold">
                                    PBLCNFT ${1000}
                                  </p>
                                </div>
                              </>
                            )}
                            {index === 11 && (
                              <>
                                <img
                                  className="card-img-top"
                                  src={im4}
                                  alt="Card image cap"
                                />
                                <div className="card-body">
                                  <p className="card-text text-white font-weight-bold">
                                    PBLCNFT ${"10,000"}
                                  </p>
                                </div>
                              </>
                            )}
                            {index === 12 && (
                              <>
                                <img
                                  className="card-img-top"
                                  src={im2}
                                  alt="Card image cap"
                                />
                                <div className="card-body">
                                  <p className="card-text text-white font-weight-bold">
                                    PBLCNFT ${"50,000"}
                                  </p>
                                </div>
                              </>
                            )}
                            {index === 13 && (
                              <>
                                <img
                                  className="card-img-top"
                                  src={im2}
                                  alt="Card image cap"
                                />
                                <div className="card-body">
                                  <p className="card-text text-white font-weight-bold">
                                    PBLCNFT ${"100,000"}
                                  </p>
                                </div>
                              </>
                            )}
                            {index === 14 && (
                              <>
                                <img
                                  className="card-img-top"
                                  src={im3}
                                  alt="Card image cap"
                                />
                                <div className="card-body">
                                  <p className="card-text text-white font-weight-bold">
                                    PBLCNFT ${"1,000,000"}
                                  </p>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
              <div className="col-12 text-md-start text-center mt-5">
                {appro ? (
                  <button
                    onClick={() => StakeAllNFTs()}
                    className="button12 text-center m px-4 py-2 me-lg-1 me-sm-5 mb-lg-0 mb-sm-3 mt-lg-0 mt-3 ms-lg-0 ms-sm-5 px-5 py-3"
                  >
                    Stake
                  </button>
                ) : (
                  <button
                    onClick={() => approveNfts()}
                    className="button12 text-center m px-4 py-2 me-lg-1 me-sm-5 mb-lg-0 mb-sm-3 mt-lg-0 mt-3 ms-lg-0 ms-sm-5 px-5 py-3"
                  >
                    Approve NFTs
                  </button>
                )}
              </div>
            </>
          ) : (
            <div className="row justify-content-around align-items-center nt_found">
              <div className="col-8 offset-md-0 offset-4">
                <img src={fd} alt="" className="img-fluid w-50" />
                <div className="col-8">
                  <h4>You have no PBLCNFTs to show</h4>
                  {/* <h4 className="ms-xl-4">No unstaked PBLCNFTs to show</h4> */}
                </div>
              </div>
            </div>
          )}
          {/* <Modull /> */}
        </div>
      </div>
    </>
  );
};

export default Staking;

// minting contract:
// https://rinkeby.etherscan.io/address/0x09d9a21c2ef47ea970fb5237443b4d75c058f9dd#code

// staking contract:
// https://rinkeby.etherscan.io/address/0xd94709eee589970cafa526269a92504ba1dc9246#code

// disable button for unseeletecd nfts
// showing walleet address
// Not Found
// The requested URL was not found on this server.
// https://gateway.thegraph.com/api/[api-key]/subgraphs/id/2zifLagmRJiDA1yAy37b28ZoekdKjs2LkD24ybkSMgRm
