
// test-net bsc addresses
// let Environment = {
//   Mint: "0x73AE2b28B12cA762afC33ECc2265875639991745",
//   // Staking: "0xed40cF90eB6Fa548D1D4E69552728390eeD7a7Cb",
//   // Staking: "0x9f592A29e28a28622CDB6DcEE20bAabeC89f91d2",
//   // Staking: "0x63e35e06ce8036b28d305f80fb4b9E689c83CCce",
//   Staking: "0x1dB4D1b37772A75165e11152CF5444654dc38148",
// };

// main-net addresses
let Environment = {
  Mint: "0xfBaE39320AA6E4Aee6829489aeD6eb2CC32a6459",
  Staking: "0x50202d4E2C96E11ED54f94e4f376D194901901e3",
  // Staking: "0x886b9bb49a6e8d4b76567C8BC33E94F7a8537960",
};


// test-net addresses goerli
// let Environment = {
//   Mint: "0x2b6E008FeAa5a84767e0A03402dc10A21Ce4E4C1",
//   Staking: "0x98B5b2ec1DbDd3f5993107AC4E2F1191bEa2b027",
//   // Staking: "0xb38Fe2455328b952C7e4B351964e4c4ee62063F5",

//   // Mint: "0x27A1aB4b4b5A4142867D5e0ea3AFFfc5276f612F",
//   // Staking: "0x1dB4D1b37772A75165e11152CF5444654dc38148",
//   // Staking: "0xed40cF90eB6Fa548D1D4E69552728390eeD7a7Cb",
//   // Staking: "0x9f592A29e28a28622CDB6DcEE20bAabeC89f91d2",
//   // Staking: "0x63e35e06ce8036b28d305f80fb4b9E689c83CCce",
// };


export default Environment;



// graph init --studio pblc-nft
// graph auth --studio 22afdd6b6ff522a10efb61b256c04da6
// graph codegen && graph build
// graph deploy --studio pblc-nft