import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getMintContract } from "../../utils/contractHelpers";

export const CheckApp = () => {
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const stakingAddress = environment.Staking;
  const MintAddress = environment.Mint;
  const contract = getMintContract(MintAddress, web3);
  const VerAppr = useCallback(
    async (account) => {
      try {
        const staked = await contract.methods
          .isApprovedForAll(account, stakingAddress)
          .call();

        return staked;
      } catch (error) {
        return error;
      }

    },
    [contract]
  );

  return { VerAppr: VerAppr };
};

export default CheckApp;
