import React from "react";
import "./footer.css";
const Footer = () => {
  return (
    <div className="container-fluid lowerBanner t-5">
      <div className="row justify-content-between">
        <div className="col-md-4  col-12 footer-text mt-5">
          <p className="text-md-start text-center"> ©2022 - PBLCNFT . All Rights Reserved </p>
        </div>
        <div className="col-md-4 col-12 footer-text mt-5">
          <p className="text-md-end text-center ">
            <a className="text-light" href="https://nwo.capital/tos/" target="_blank">
              Terms of Service
            </a>
           <span> | </span>
            <a className="text-light" href="https://nwo.capital/privacy-policy/" target="_blank">
              Privacy Policy
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
export default Footer;
