import random from 'lodash/random'

// Array of available nodes to connect to
export const nodes = ["https://mainnet.infura.io/v3/6e516a50f8404231bcb030ce93cf466f", "https://mainnet.infura.io/v3/6e516a50f8404231bcb030ce93cf466f", "https://mainnet.infura.io/v3/6e516a50f8404231bcb030ce93cf466f"]

const getNodeUrl = () => {
  const randomIndex = random(0, nodes.length - 1)
  return nodes[randomIndex]
}

export default getNodeUrl