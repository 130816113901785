// import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import "./cards.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import index_im from "../../Assets/Final Animation.mp4";
import BalanceOF from "../../hooks/dataFetchers/balanceBatch";
import CheckApp from "../../hooks/dataSenders/approveAll";
import Rewards from "../../hooks/dataFetchers/checkRewards";

const Cards = () => {
  const { Balances } = BalanceOF();
  const [total, setTotal] = useState(0);
  const [reward, setreward] = useState(0);
  const { CRewards } = Rewards();
  const { account } = useWeb3React();
  const { VerAppr } = CheckApp();
  const [st, setSt] = useState([]);
  // const [staked, setStaked] = useState("");
  // const [data, setData] = useState("");
  // const { account } = useWeb3React();
  // const getStakedNfts = () => {
  //     // http://192.168.18.40:6500/getStaked
  //     axios
  //         .post("https://laceman.herokuapp.com/getStaked", {
  //             accountAddress: "0x93e605A240027b93aA4111BF16c4979beaAc6a7c",
  //         })
  //         .then((res) => {
  //             setStaked(res.data.data);
  //         
  //         })
  //         .catch((err) => {
  //             return false;
  //         });

  // };
  // useEffect = () => {
  //     // getStakedNfts();
  //    
  // };


  useEffect(async () => {
    if (account) {
      let acc = [
        account, account, account, account,
        account, account, account, account,
        account, account,

        account,
        account, account, account,
        account];

      // let tokas = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

      // mainnet ids
      let tokas = [
        "35765170590814674817329738340066840990845959557561780372227731964376303796239",
        "35765170590814674817329738340066840990845959557561780372227731964376303796229",
        "35765170590814674817329738340066840990845959557561780372227731964376303796230",
        "35765170590814674817329738340066840990845959557561780372227731964376303796231",
        "35765170590814674817329738340066840990845959557561780372227731964376303796232",
        "35765170590814674817329738340066840990845959557561780372227731964376303796233",
        "35765170590814674817329738340066840990845959557561780372227731964376303796234",
        "35765170590814674817329738340066840990845959557561780372227731964376303796235",
        "35765170590814674817329738340066840990845959557561780372227731964376303796236",
        "35765170590814674817329738340066840990845959557561780372227731964376303796225",

        "35765170590814674817329738340066840990845959557561780372227731964376303796227",
        "35765170590814674817329738340066840990845959557561780372227731964376303796237",
        "35765170590814674817329738340066840990845959557561780372227731964376303796242",

        "35765170590814674817329738340066840990845959557561780372227731964376303796243",

        "35765170590814674817329738340066840990845959557561780372227731964376303796244"
      ];

      // testnet ids
      // let tokas = [
      //   "35765170590814674817329738340066840990845959557561780372227731964376303796225",
      //   "35765170590814674817329738340066840990845959557561780372227731964376303796228",
      //   "35765170590814674817329738340066840990845959557561780372227731964376303796227",
      // ];

      // let tokas = [
      //   "35765170590814674817329738340066840990845959557561780372227731964376303796239",
      //   "35765170590814674817329738340066840990845959557561780372227731964376303796233",
      //   "35765170590814674817329738340066840990845959557561780372227731964376303796233",
      // ];

      const res = await Balances(acc, tokas);
      let dumArr = [];
      if (res) {
        for (let i of res) {
          if (parseInt(i) > 0) {
            dumArr.push(i);
          }
        }
      }
      await setTotal(dumArr);
      getStakedNfts();
    }
  }, [account]);

  const getStakedNfts = () => {
    var data = JSON.stringify({
      query: `{
        stakes(where: {staker: "${account}"}){
          staker
          id
          tokenId
        }
      }`,
      variables: {},
    });

    var config = {
      method: "post",
      // url: "https://api.thegraph.com/subgraphs/name/muhammadwaqas4/pblc-staking/graphql",
      // url: "https://api.studio.thegraph.com/query/36920/nwo-pblcnft-stake/v0.0.1",
      // url: "https://api.studio.thegraph.com/query/36920/nwo-pblcnft-stake/v0.0.1",
      url: "https://api.studio.thegraph.com/query/36920/nwo-pblcnft-stake/0.0.6",
      // url: "https://gateway.thegraph.com/api/QmVsXoASybPKwsg2qwyKhek6YJUQhr1uGw5cJMQnX731Mg/subgraphs/id/2zifLagmRJiDA1yAy37b28ZoekdKjs2LkD24ybkSMgRm",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        let stak = response.data?.data?.stakes;

        setSt(stak);
        // setStaked(stak);
      })
      .catch(function (error) {

      });
  };

  console.log("setStsetStsetSt", st)

  useEffect(async () => {
    let rews = 0;
    if (st.length > 0) {
      for (let i of st) {
        let res = await CRewards(i?.staker, i?.tokenId);
        if (res) {
          res = parseInt(res) / 1000000000000000000;
          rews = rews + res;
          await console.log("rrrrrrrr", res, rews)
        }
      }
      await setreward(rews?.toFixed(4))
    }
  }, [st])

  return (

    <div className="container-fluid">
      <div className="row im_back p-0">
        <video
          width="100%"
          // height="auto"
          loop="true"
          autoplay="autoplay"
          // controls
          muted
          src={index_im}
          type="video/mp4"
        />
        <div className="col-lg-4 col-md-6 mx-auto mintCard">
          <div className="run1 d-flex flex-column align-items-center">
            <div className="collect-container1  w-100 h-100">
              <div className="head1">
                <h3>Staking Info</h3>
              </div>
              <div className="collect">
                {/* <div className="input"> Total NFTs {total}</div>
                <hr /> */}
                <div className="input">
                  Staked PBLCNFTs <span>{st?.length ? st?.length : 0}</span>
                </div>
                <hr />

                <div className="input">
                  Unstaked PBLCNFTs<span> {total.length ? total.length : 0}</span>
                </div>
                <hr />

                <div className="input">
                  Current Reward<span> {reward ? reward : 0}</span>
                </div>

                <hr />
                <div className="input">
                  APY Rewards <span>16.18%</span>
                </div>
              </div>
            </div>

            <Link style={{ zIndex: 20 }} className="mt-4" to="/staking">
              <button
                style={{ cursor: "pointer" }}
                className="ms-auto buttonstake"
              >
                Start Staking
              </button>
            </Link>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Cards;









