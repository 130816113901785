import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { stakeContract, getMintContract } from "../../utils/contractHelpers";

export const SetApp = () => {
  const web3 = useWeb3();
  const mint = environment.Mint;
  const stakingAddress = environment.Staking;
  const contract = getMintContract(mint, web3);
  const Approve = useCallback(
    async (account) => {
      try {

        const gas = await contract.methods
          .setApprovalForAll(stakingAddress, true)
          .estimateGas({ from: account, gasPrice: "81500000000" });

        const staked = await contract.methods
          .setApprovalForAll(stakingAddress, true)
          .send({ from: account, gas: gas, gasPrice: "81500000000" })
          .on("transactionHash", (tx) => {
            return tx.transactionHash;
          })
        return staked;
      } catch (error) {
        throw error;
      }
    },
    [contract]
  );

  return { Approve: Approve };
};

export default SetApp;
