import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { stakeContract } from "../../utils/contractHelpers";

export const StakeForAll = () => {
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const stakingAddress = environment.Staking;
  const contract = stakeContract(stakingAddress, web3);
  const StakeTokens = useCallback(
    async (tokenIds, amounts) => {
      console.log("tokenIds, amounts", tokenIds, amounts)
      try {
        const gas = await contract.methods
          .stakeMany(tokenIds, amounts)
          .estimateGas({ from: account });

        const staked = await contract.methods
          .stakeMany(tokenIds, amounts)
          .send({ from: account, gas: gas, gasPrice: "81500000000" })
          .on("transactionHash", (tx) => {
            return tx.transactionHash;
          })

        return staked;
      } catch (error) {
        console.log("aaa", error)
        throw error;
      }

    },
    [account, contract]
  );

  return { StakeAll: StakeTokens };
};

export default StakeForAll;
