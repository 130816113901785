import React from "react";
import "./contact.css";
import im from "../Contact/telegram.png";
import { Link } from "react-router-dom";

const Contact = ({display}) => {
  return (
    <div className="container-fluid lowerBanner">
      <div className="row justify-content-center">
      <div className="row pt-5 mt-4 pb-2 justify-content-center">
        <div className={`col-lg-6 text-center text-white ${display}`}>
          <p>
            Connect your wallet to stake PBLCNFTs. First; approve Your NFTs to stake, second; select NFTs to stake and press stake button. 16.18% Annual Percentage Yield (APY) on each PBLCNFTs staked, a daily reward of 16.18 / 365 = 0.04432876712 % $STATE tokens reward given on unstaking of NFTs.
          </p>
        </div>
      </div>
        <div className="col-lg-7 col-12 mt-sm-5ss mt-0">
          <div className="run2 mt-5">
            <div className="collect-container2 main mb-5">
              <div className="container ">
                <div className="row justify-content-md-between justify-content-center py-3">
                  
                  <div className="col-md-8 col-8 d-flex align-items-center ">
                    <div className="head2">
                      <h3 className="text-md-start text-center">
                        <h6>Get Assistance and Ask Questions on Telegram</h6>
                      </h3>
                    </div>
                  </div>
                  <div className="col-md-4 col-8 d-flex align-items-center justify-content-md-end justify-content-center mt-md-0 mt-4">
                    <div className="head2">
                      <a href="https://t.me/Publicae" target="blank">
                        <img
                          src={im}
                          alt=""
                          className=" set w-25 discord_im img-fluid imgo"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
