import web3NoAccount from './web3'
import mintAbi from './mintAbi.json';
import stakeAbi from './stakeAbi.json';
import approveAbi from './approveAbi.json';

const getContract = (abi, address, web3) => {
    const _web3 = web3 ?? web3NoAccount;
    // console.log('_web3',_web3);
    return new _web3.eth.Contract(abi, address)
}

// export const getBep20Contract = (address, web3) => {
//     return getContract(approveAbi, address, web3)
// }

export const getMintContract = (address, web3) => {
    return getContract(mintAbi, address, web3)
}

export const stakeContract = (address, web3) => {
    return getContract(stakeAbi, address, web3)
}